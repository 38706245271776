import { Button } from "@resource/atlas/button/Button";
import { useDialogStore } from "@resource/atlas/dialog-v2/Dialog";
import { atlasRingLock } from "@resource/atlas/icons";
import { Menu } from "@resource/atlas/menu";
import type { AtlasMenuContentItem } from "@resource/atlas/menu/types";
import { useMenuState } from "@resource/atlas/menu/use-menu-state";
import { useAuthContext } from "auth/context";
import { useRoutes } from "client/app/(candidate guides)/guide/[organizationSlug]/[shortId]/__utils/routes";
import { getGuideInternalPageUrlFromPublicUrl } from "client/components/internal/__utils/urls";
import useCopyGuideMagicLink from "client/components/internal/hooks/useCopyGuideMagicLink";
import NextLink from "next/link";
import { useMemo } from "react";
import { filterOutNullsAndUndefined } from "shared/utils/filtering";

import { canResetCandidateForDev } from "../__utils/utils";
import { ResetCandidateForDevConfirmationDialog } from "../guide/ResetCandidateForDevConfirmationDialog";

export function InternalDashboardsMenu({
  guidePublicUrl,
  guideId,
}: {
  guidePublicUrl?: string;
  guideId?: string;
}) {
  const routes = useRoutes();
  const { user } = useAuthContext();
  const menuState = useMenuState();
  const resetCandidateConfirmationDialogStore = useDialogStore();

  const { copyGuideMagicLink } = useCopyGuideMagicLink({ guideId });

  const internalGuidePageHref = guidePublicUrl
    ? getGuideInternalPageUrlFromPublicUrl(guidePublicUrl)
    : null;

  const items = useMemo((): (AtlasMenuContentItem | null)[] => {
    return [
      (routes.organizationSlug !== "__unknown__" &&
        routes.shortId !== "__unknown__" &&
        routes.shortId &&
        routes.relativePath !== "/internal") ||
      !!internalGuidePageHref
        ? {
            _type: "item",
            children: "Internal Guide Page",
            key: "internal-guide-page",
            renderWrapper: (props) => (
              <NextLink
                href={
                  internalGuidePageHref ??
                  `/internal/guide/${routes.organizationSlug}/${routes.shortId}`
                }
                {...props}
              />
            ),
          }
        : null,
      guideId
        ? {
            _type: "item",
            children: "Copy Guide magic link",
            key: "copy-guide-magic-link",
            onClick: copyGuideMagicLink,
          }
        : null,
      guideId && canResetCandidateForDev()
        ? {
            _type: "item",
            children: "Reset candidate for dev",
            key: "reset-candidate-for-dev",
            isDestructive: true,
            onClick: () => {
              resetCandidateConfirmationDialogStore.show();
            },
          }
        : null,
      {
        _type: "item",
        children: "Guide URL Lookup",
        key: "guide-url-lookup",
        renderWrapper: (props) => (
          <NextLink
            href="/internal/guide-url-lookup"
            {...props}
            target="_blank"
          />
        ),
      },
      {
        _type: "item",
        children: "Emails",
        key: "guide-url-lookup",
        renderWrapper: (props) => (
          <NextLink href="/emails" {...props} target="_blank" />
        ),
      },
      {
        _type: "item",
        children: "Atssync Dashboard",
        key: "atssync-dashboard",
        renderWrapper: (props) => (
          <NextLink href="/internal/atssync" {...props} target="_blank" />
        ),
      },
      {
        _type: "item",
        children: "Internal Lookups",
        key: "lookups",
        renderWrapper: (props) => (
          <NextLink href="/internal/lookups" {...props} target="_blank" />
        ),
      },
      {
        _type: "item",
        children: "History Tables",
        key: "history-tables",
        renderWrapper: (props) => (
          <NextLink href="/internal/history" {...props} target="_blank" />
        ),
      },
      {
        _type: "item",
        children: "Backfill Migrator",
        key: "backfill-migrator",
        renderWrapper: (props) => (
          <NextLink
            href="/internal/backfill-migrator"
            {...props}
            target="_blank"
          />
        ),
      },
      {
        _type: "item",
        children: "Scheduling Requests Internal",
        key: "scheduling-requests-internal",
        renderWrapper: (props) => (
          <NextLink
            href="/internal/scheduling-requests?assignee=all"
            {...props}
            target="_blank"
          />
        ),
      },
      {
        _type: "item",
        children: "Google Dashboard",
        key: "google-dashboard",
        renderWrapper: (props) => (
          <NextLink href="/internal/google" {...props} target="_blank" />
        ),
      },
      {
        _type: "item",
        children: "Zoom Dashboard",
        key: "zoom-dashboard",
        renderWrapper: (props) => (
          <NextLink href="/internal/zoom" {...props} target="_blank" />
        ),
      },
      {
        _type: "item",
        children: "Queues Dashboard",
        key: "queues-dashboard",
        renderWrapper: (props) => (
          <NextLink href="/internal/queues" {...props} target="_blank" />
        ),
      },
      {
        _type: "item",
        children: "Import Profiles",
        key: "import-profiles",
        renderWrapper: (props) => (
          <NextLink
            href="/internal/import-profiles"
            {...props}
            target="_blank"
          />
        ),
      },
    ];
  }, [
    routes.organizationSlug,
    routes.shortId,
    routes.relativePath,
    internalGuidePageHref,
    guideId,
    copyGuideMagicLink,
    resetCandidateConfirmationDialogStore,
  ]);

  if (!user?.isSuperuser) {
    return null;
  }

  return (
    <>
      <Menu.Root placement="bottom-end" state={menuState}>
        <Menu.Trigger>
          <Button isGhost icon={atlasRingLock} />
        </Menu.Trigger>
        <Menu.Content items={items.filter(filterOutNullsAndUndefined)} />
      </Menu.Root>
      {guideId && canResetCandidateForDev() && (
        <ResetCandidateForDevConfirmationDialog
          store={resetCandidateConfirmationDialogStore}
          guideId={guideId}
        />
      )}
    </>
  );
}
